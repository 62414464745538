<template>
  <div id="projects">
    
            <projects-list></projects-list>
             
  </div>
</template>

<script>
    import ProjectsList from './components/ProjectsList'

    export default{
        name: 'ProjectsView',
        components: {ProjectsList}
    }
</script>
