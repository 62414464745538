<template>
  <div id="projects-list">

    <b-card no-body class="card mb-0" style="margin-bottom:1.5rem !important" v-if="show_admin_sections">
      <div class="m-2" >  
        <b-card no-body class="mb-0">  
              <div class="">
                <b-row> 
                  <b-col xl="6" sm="12">
                    <b-form-group label="Inmobiliarias" label-for="inmobiliarias">
                      <v-select
                        v-model="filtersForm.real_state_agency"
                        label="name"
                        placeholder="Selecciona una inmobiliaria"
                        :options="realStateAgencies"
                        :reduce="realStateAgencies => realStateAgencies.id">
                        <span slot="no-options">
                          No se encontraron opciones
                        </span>
                      </v-select>
                    </b-form-group>
                    
                  </b-col>  

                  <b-col xl="6" sm="12">
                    <b-form-group label="Proyectos" label-for="projects">
                      <v-select
                        autocomplete
                        multiple
                        v-model="filtersForm.project"
                        label="name"
                        placeholder="Selecciona un Proyecto"
                        :options="filteredProjects"
                        :reduce="filteredProjects => filteredProjects.id"
                        >
                        <span slot="no-options">
                          No se encontraron opciones
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>   
                  
                </b-row>
              </div>


        </b-card>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12"> 
            <b-overlay :show="overlayList" opacity="1.0" spinner-small spinner-variant="primary">
              <div class="d-flex align-items-end justify-content-end">
              <div>

                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()">
                  <b-button variant="primary" class="mr-1" @click.prevent="_exportProjects()" v-if="permissions_visible.download_projects">
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Descargar Proyectos
                    </span>
                  </b-button>
                </b-overlay>

              </div>

              <b-button variant="primary" @click.prevent="_createProject()" v-if="permissions_visible.create_projects">
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"/>
                <span class="align-middle">
                  Nuevo Proyecto
                </span>
              </b-button>

            </div>
              <div class="scroller mt-2 mb-1">
              <b-table width="100%" id="project-table" hover striped show-empty class="position-relative mb-1" :items="projects.data" :fields="tableColumns"
                primary-key="id" empty-text="Datos no Disponibles">
                
                <template #cell(medias)="data">
                  <div class="container-img" width="50px">
                    <b-img height="100" :src="_getMiniature(data.item.medias)" />
                  </div>
                </template>

                <template #cell(name)="data">
                  <a :href="`${url_public_site}proyecto/${data.item.id}/${data.item.slug}`" target="_blank" rel="noopener noreferrer">{{ data.item.name }}</a>
                </template>

                <template #cell(subsidytype)="data">
                  <span> <div v-for="(sub, index) in data.item.subsidies" :key="index">{{sub.subsidy.name}}</div> </span>
                </template>
                
                <template #cell(type_property)="data"> <span>{{ _getTypeProperty(data.item.type_property) }}</span> </template>
                <template #cell(visible)="data">
                  <b-badge pill :variant="data.item.visible ? 'light-success' : 'light-danger'">
                    {{ data.item.visible ?  'Visible' : 'Oculto' }}
                  </b-badge>
                  
                </template>

                <template #cell(enabled-disabled)="data">
                  <b-form-checkbox class="custom-control-primary" switch inline name="check-visible" v-model="data.item.visible" 
                  @change="_changeVisible(data.item)" v-if="permissions_visible.edit_projects" >
                  </b-form-checkbox>
                </template>

                <template #cell(important)="data" >
                  <b-form-checkbox class="custom-control-primary" switch inline name="important" v-model="data.item.important" value="1" unchecked-value="0" 
                  @change="_editProjectImportant(data.item)" v-if="permissions_visible.edit_projects" >
                  </b-form-checkbox>
                </template>

                <template #cell(plan_oro)="data" >
                  <b-form-checkbox class="custom-control-primary" switch inline name="planspayment" v-model="data.item.plansbutton"
                  @change="_editPlanOro(data.item)" v-if="show_admin_sections" >
                  </b-form-checkbox>  
                </template>

                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon size="16" icon="MoreVerticalIcon" class="align-middle text-body"/>
                    </template>
                    <b-dropdown-item @click.prevent="_webhooksProject(data.item)" v-if="permissions_visible.edit_webhook">
                      <feather-icon icon="FileTextIcon"/> <span class="align-middle ml-50">Webhook</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="_auditProject(data.item)" v-if="permissions_visible.audits_project">
                      <feather-icon icon="BookmarkIcon"/> <span class="align-middle ml-50">Auditoria</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="_editProject(data.item)" v-if="permissions_visible.edit_projects">
                      <feather-icon icon="EditIcon" /> <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="_deleteProject(data.item)" v-if="permissions_visible.delete_projects">
                      <feather-icon icon="TrashIcon"  /> <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

              </b-table>

            </div>
            <b-pagination align="right" hide-goto-end-buttons v-model="currentPage" :total-rows="projects.total" />
            </b-overlay>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
  import {mapActions, mapState,mapGetters } from 'vuex';
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    name: 'ProjectsList',
    data () {
      return {
        url_public_site: process.env.VUE_APP_URL_API,
        show_admin_sections : false,
        permissions_visible:{
          audits_project:false,
          show_projects:false,
          assign_project:false,
          edit_projects:false,
          delete_projects:false,
          create_projects:false,
          change_status_project:false,
          change_important_project:false,
          download_projects:false,
          edit_webhook:false,
        },
        setCache: true,
        overlayList: false,
        overlayForm: false,
        currentPage: 1,
        selectProject: [],
        tableColumns: [
          { key: 'medias', label: 'Miniatura'},
          { key: 'name', sortable: false, label: 'Nombre Proyecto' },
          { key: 'type_property', sortable: false, label: 'Tipo de propiedad' },
          { key: 'subsidytype', sortable: false, label: 'Tipo de subsidio' },
          { key: 'visible', sortable: false, label: 'Estado' },
          { key: 'enabled-disabled', sortable: false, label: 'Oculto/Visible' }, 
        ],
        is_admin:false,
        filtersForm: {
          project: '',
          real_state_agency: '',
          page: 1
        },
        initialProjects:[],
        filteredProjects:[]
      }
    },

    watch:{
      currentPage (val) {
        this._fetchProjects()
      },
      "filtersForm.real_state_agency": function (val) { 
        
        let self = this;  
        if(val == null){ 
          this.filteredProjects = this.initialProjects;
          if(this.setCache){ 
            this.filtersForm.project = [];
          }
        }else{ 
          if(this.setCache){ 
            this.filteredProjects = [];
            this.filtersForm.project = [];
          }
          this.initialProjects.forEach( function(valor, indice, array) {
              if(valor.realstateagency.id == val){
                self.filteredProjects.push(valor)
              }
          });
        }
        if(this.setCache){ 
          this.projectFilters.realStateAgencie = val; 
          this.projectFilters.filteredProjects = this.filteredProjects; 
        } 
        this._fetchProjects();
      },
      "filtersForm.project": function (val) {  
        if(this.setCache){
          this.projectFilters.project = this.filtersForm.project;
        } 
        this._fetchProjects()
      },
    },
    computed: {
      ...mapState('projects',['projects','projectFilters']),
      ...mapGetters('profile',['realStateAgencies']),
    },
    beforeMount() {
      var self = this;   
      if(this.projectFilters.realStateAgencie == ''){
        this._fetchProjects();
        this.fetchProjectsRelatedToMe()
        .then(res => { 
          self.initialProjects = res.data.projectsrelatedtome;
          self.filteredProjects = self.initialProjects;  
        });

      }else{

        this.fetchProjectsRelatedToMe()
        .then(res => { 
          self.initialProjects = res.data.projectsrelatedtome;
        });

        this.setCache = false;
        this.filtersForm.real_state_agency = this.projectFilters.realStateAgencie;  
        this.filtersForm.project = this.projectFilters.project;  
        this.filteredProjects = this.projectFilters.filteredProjects;  

        setTimeout(function(){ self.setCache = true; }, 300);  
      }
    },
    mounted(){
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      var userrole = this.$PermissionHelper.getRole().toLowerCase(); 
      switch(userrole){
        case "admin":
        case "superadmin":
            this.tableColumns = [
              { key: 'medias', label: 'Miniatura'},
              { key: 'name', sortable: false, label: 'Nombre Proyecto' },
              { key: 'type_property', sortable: false, label: 'Tipo de propiedad' },
              { key: 'subsidytype', sortable: false, label: 'Tipo de subsidio' },
              { key: 'visible', sortable: false, label: 'Estado' },
              { key: 'enabled-disabled', sortable: false, label: 'Oculto/Visible' },
              { key: 'important', sortable: false, label: 'Destacado' },
              { key: 'plan_oro', sortable: false, label: 'Plan Oro' },
              { key: 'actions', label: 'Acciones' },
            ];
            this.show_admin_sections = true;
        break;
        case "realstateadmin":
          this.tableColumns = [
              { key: 'medias', label: 'Miniatura'},
              { key: 'name', sortable: false, label: 'Nombre Proyecto' },
              { key: 'type_property', sortable: false, label: 'Tipo de propiedad' },
              { key: 'subsidytype', sortable: false, label: 'Tipo de subsidio' },
              { key: 'visible', sortable: false, label: 'Estado' },
              { key: 'enabled-disabled', sortable: false, label: 'Oculto/Visible' },
              { key: 'plan_oro', sortable: false, label: 'Plan Oro' },
              { key: 'actions', label: 'Acciones' },
            ];
        case "realstatevendor":
          this.tableColumns = [
              { key: 'medias', label: 'Miniatura'},
              { key: 'name', sortable: false, label: 'Nombre Proyecto' },
              { key: 'type_property', sortable: false, label: 'Tipo de propiedad' },
              { key: 'subsidytype', sortable: false, label: 'Tipo de subsidio' },
              { key: 'visible', sortable: false, label: 'Estado' },
              { key: 'enabled-disabled', sortable: false, label: 'Oculto/Visible' },
              { key: 'plan_oro', sortable: false, label: 'Plan Oro' },
              { key: 'actions', label: 'Acciones' },
            ];
        break;
      } 
       
    },
    methods: {
      ...mapActions('projects',['deleteProjects', 'exportProjects', 'changeVisible', 'editProjectImportant', 'fetchProjects' , 'changePlan']),
      ...mapActions('profile', ['fetchRealStateAgenciesNoPagination','fetchRealStateAgencies','fetchProjectsRelatedToMe','fetchLocations','fetchProjectStatus','fetchSubsidyTypes']),
      async _fetchProjects() { 
        let data = {
          page: this.currentPage ,
          project_id:this.filtersForm.project,
          real_state_agency:this.filtersForm.real_state_agency,
        }

        this.overlayList = true
        await this.fetchProjects(data)
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.overlayList = false;
        })
      },
      async _editPlanOro(data){  
        let planForm = {project_id: data.id, plan: data.planspayment == null ? '2' : data.planspayment.id == 2 ? '1' : '2'}

        await this.changePlan(planForm)
        .then(res => { 
          if(res.data.editPlanProject.status == '1'){

            if(res.data.editPlanProject.project.planspayment == null){
              data.plansbutton = false;
              this.$toast({
                component: ToastificationContent,
                props: { title: 'Problemas al modificar el plan', icon: 'AlertCircleIcon', variant: 'danger', },
              })
            }else{ 
              data.plansbutton = res.data.editPlanProject.project.planspayment.id == 2;
              this.$toast({
                component: ToastificationContent,
                props: { title: 'Plan modificado con éxito', icon: 'ThumbsUpIcon', variant: 'success', },
              })
            }

          }else{

            data.plansbutton = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.editPlanProject.info ? res.data.editPlanProject.info : 'Número máximo de planes alcanzado', 
                icon: 'AlertCircleIcon', variant: 'warning', },
            })

          } 
          
        }).catch(err => {
            data.plansbutton = false; 
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el plan',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
        })
      },
      async _changeVisible(data) {
        let visibleForm = {project_id: data.id, visible: data.visible}
        await this.changeVisible(visibleForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estado modificado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el estado',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
        })
      },
      async _editProjectImportant(data) {
        let importantForm = {project_id: data.id, important: data.important}
        await this.editProjectImportant(importantForm)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estado modificado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el estado',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
        })
      },
      async _exportProjects() {
        this.overlayForm = true

        var data = {
          project_id:this.filtersForm.project,
          real_state_agency:this.filtersForm.real_state_agency,
        };

        await this.exportProjects(data)
        .then(res => {}).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al exportar el archivo',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.overlayForm = false
        })
      },
      _createProject() {
        this.$router.push({ name: 'projects-create'})
      },
      _auditProject(project) {
        this.$router.push({ name: 'projects-audits', params: { id: project.id, audits: project }})
      },
      _editProject(project) {
        project['page'] = this.currentPage
        this.$router.push({ name: 'projects-edit', params: { id: project.id, project: project }})
      },
      _webhooksProject(project) {
        this.$router.push({ name: 'projects-webhooks', params: { id: project.id, project: project}})
      },
      _deleteProject(project) {
        this.$swal({
          title: '¿Estás seguro que deseas eliminar este proyecto?',
          text: 'La siguiente acción eliminará el proyecto seleccionado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this._confirmDelete(project);
          }
        })
      },
      async _confirmDelete(project) {
        project['page'] = this.currentPage
        await this.deleteProjects(project)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Proyecto eliminado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al eliminar el proyecto',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
      _getMiniature(media){
        let miniature = media.filter(media => {return media.mediatype.id === 3})
        return miniature.length > 0 ? miniature[0].full_url : "https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png"
      },
      _getTypeProperty(type){
        return type === 'house' ? 'Casa' : 'Departamento'
      },
      _onHidden() {
        if(this.$refs.button != null){
          this.$refs.button.focus()
        }
      },
    }
  }
</script>

<style scoped>
  .container-img{
    width: 220px;
  }
  .con-example-images {
    max-height: 500px;
    overflow: auto !important;
  }
  .scroller {
        width: 100%;
        overflow-x: scroll;
        white-space:nowrap;
    }
</style>